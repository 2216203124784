import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import GatsbyImage from 'gatsby-image'

import LogoImage from './logo'
import MutedIcon from '../images/volume-muted.svg'
import UnmutedIcon from '../images/volume-max.svg'
import { media } from '../utils/mediaQueries'

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  margin-top: 100px;
  cursor: pointer;

  ${ media.medium`
    margin-top: 70px;
  ` }
`

const HeadlineContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: opacity 1s;
  opacity: ${ ({ loading }) => loading ? 1 : 0 };
`

const PlaceholderImage = styled(GatsbyImage)`
  position: absolute !important;
  top: 0;
  width: 100%;
  z-index: 0;
`

const Headline = styled.h3`
  color: white;
  z-index: 1;
  text-align: center;
  font-weight: 400;
`

const Logo = styled(LogoImage)`
  width: 120px;
  height: auto;
  z-index: 1;

  img {
    width: 100%;
  }
  
  ${ media.medium`
    width: 80px;
  ` }
`

const UnmuteButton = styled(MutedIcon)`
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: 10px;
  right: 10px;
`

const MuteButton = styled(UnmutedIcon)`
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: 10px;
  right: 10px;
`

const VideoContainer = styled.div`
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: ${ ({ width, height }) => `${ height / width * 100 }%` };
  position: relative;
`

const Video = styled.video`
  width: 100%;
  visibility: hidden;

  visibility: ${ ({ isLoading }) => isLoading ? 'hidden' : 'visible' };
`

class HeroVideo extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: true,
      muted: true
    }

    this.video = React.createRef()
    this.playVideoTimeout = null
  }

  componentDidMount () {
    fetch(`https://onelineplayer.glitch.me/youtube?url=https://www.youtube.com/watch?v=${ this.props.providerUid }`)
      .then(response => {
        return response.json()
      })
      .then(data => {
        this.video.current.src = isMobile() ? data.video.medium : data.video.hd720
        this.video.current.addEventListener('timeupdate', this.onVideoLoaded)
        this.video.current.play()
      })
  }

  componentWillUnmount () {
    this.video.current.pause()
    this.video.current.removeEventListener('timeupdate', this.onVideoLoaded)
    clearTimeout(this.playVideoTimeout)
  }

  toggleVolume = () => {
    // clearTimeout(this.volumeFadeTimeout)

    if (this.state.loading) {
      return
    }

    if (this.state.muted) {
      this.setState({
        muted: false
      }, () => {
        this.video.current.muted = false
      })
    } else {
      this.setState({
        muted: true
      }, () => {
        this.video.current.muted = true
      })
    }

    // if (this.video.current.volume === 0) {
    //   this.unMute()
    // } else {
    //   this.mute()
    // }
  }

  onVideoLoaded = () => {
    if (this.video.current.currentTime > 0) {
      this.video.current.removeEventListener('timeupdate', this.onVideoLoaded)
      this.playVideoTimeout = setTimeout(() => {
        this.setState({
          loading: false
        })
      }, 2000)
    }
  }

  // OLD MUTE FADE FUNCTIONS, MIGHT LEAVE FOR LATER
  // mute = () => {
  //   if (this.video.current.volume > 0) {
  //     this.video.current.volume = (this.video.current.volume - 0.01).toFixed(2)
  //     this.volumeFadeTimeout = setTimeout(this.mute, 2)
  //   }
  // }

  // unMute = () => {
  //   if (this.video.current.volume < 0.6) {
  //     this.video.current.volume = (this.video.current.volume + 0.01).toFixed(2)
  //     this.volumeFadeTimeout = setTimeout(this.unMute, 2)
  //   }
  // }

  render () {
    const { loading, muted } = this.state
    const { width, height, placeholderImage, headline } = this.props

    return (
      <Container onClick={this.toggleVolume}>
        <VideoContainer width={width} height={height}>
          <Video
            loading={loading}
            ref={this.video}
            muted={true}
            webkit-playsinline="true"
            playsInline={true}
            loop={true}
          ></Video>
          {muted && <UnmuteButton/>}
          {!muted && <MuteButton/>}
        </VideoContainer>
        <HeadlineContainer loading={loading}>
          <PlaceholderImage fluid={placeholderImage.fluid} alt={placeholderImage.alt}/>
          <Logo/>
          <Headline>{headline}</Headline>
        </HeadlineContainer>
      </Container>
    )
  }
}

function isMobile () {
  if (typeof window !== 'undefined') {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      window.navigator.userAgent.substr(0, 4)
    )
    ) {
      return true
    }
  }

  return false
}

HeroVideo.propTypes = {
  headline: PropTypes.string,
  placeholderImage: PropTypes.object,
  providerUid: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

export default HeroVideo
