import React from 'react'
import { Link as GatsbyLink, graphql } from 'gatsby'
import styled from 'styled-components'
import Button from '../components/button'
import PageWrapper from '../components/pageWrapper'
import CardSmall from '../components/cardSmall'
import HeroVideo from '../components/heroVideo'
import Footer from '../components/footer'

const Description = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
`

const Link = styled(GatsbyLink)`
  display: block;
  width: 100%;
  height: 100%;
`

const IndexPage = ({ data: { datoCmsHomePage: {
  heroImage,
  video,
  heroHeadline,
  description,
  sections
} } }) => (
  <>
    <HeroVideo
      providerUid={video.providerUid}
      placeholderImage={heroImage}
      headline={heroHeadline}
      width={video.width}
      height={video.height}
    />
    <PageWrapper>
      <Description>
        {description}
      </Description>
      <Button>
        <Link to='/yachts'>
          book a charter
        </Link>
      </Button>
      {sections.map(({ name, description, media, callToAction, to, originalId }) => (
        <CardSmall
          title={name}
          text={description}
          media={media}
          callToAction={callToAction}
          to={`/${ to.toLowerCase() }`}
          key={originalId}
        />
      ))}
    </PageWrapper>
    <Footer/>
  </>
)

export const query = graphql`
  query homepage {
    datoCmsHomePage {
      heroHeadline
      description
      heroImage {
        alt
        fluid(maxWidth: 1000, maxHeight: 563, imgixParams: {
          fm: "jpg",
          auto: "compress,format",
          fit: "crop",
          crop: "faces"
        }) {
          ...GatsbyDatoCmsFluid
        }
      }
      video {
        width
        height
        providerUid
      }
      sections {
        name
        description
        originalId
        callToAction
        to
        media {
          id
          alt
          fluid(maxWidth: 400, maxHeight: 400, imgixParams: {
            fm: "jpg",
            auto: "compress,format",
            fit: "crop",
            crop: "faces"
          }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
    }
  }
`

export default IndexPage
